import { PERM } from "@ero/app-common/enums";
import { SearchSpec } from "@ero/app-common/util/SearchSpec";
import { Avatar } from "@mui/material";
import { GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import React from "react";
import { useTranslation } from "react-i18next";

export const useColumns = (): GridColDef<GridValidRowModel>[] => {
  const [t] = useTranslation();

  return [
    {
      field: "avatar",
      headerName: t("employees.fields.avatar"),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Avatar
            src={params.value}
            style={{
              width: 30,
              height: 30,
            }}
          />
          <>{}</>
        </div>
      ),
      disableExport: true,
      maxWidth: 70,
    },
    {
      field: "name",
      headerName: t("general.fields.name"),
      valueGetter: (_, row) => `${row.firstName} ${row.lastName}`,
    },
    {
      field: "userName",
      headerName: t("general.fields.userName"),
    },
    {
      field: "permission",
      headerName: t("employees.fields.permission"),
      valueGetter: (value) => {
        switch (value) {
          case PERM.ORGANIZER:
            return t("employees.permission.organizer");
          case PERM.DRIVER:
            return t("employees.permission.driver");
        }
      },
    },
    {
      field: "email",
      headerName: t("general.fields.email"),
    },
    {
      field: "phone",
      headerName: t("general.fields.phone"),
    },
    {
      field: "notes",
      headerName: t("general.fields.notes"),
    },
  ];
};

export const defaultHiddenColumns = {
  status: false,
};

export const quickSearchColumns: SearchSpec["properties"] = [
  { type: "string", access: ["firstName"] },
  { type: "string", access: ["lastName"] },
  { type: "string", access: ["userName"] },
  { type: "string", access: ["email"] },
  { type: "string", access: ["phone"] },
  { type: "string", access: ["notes"] },
];
