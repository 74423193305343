import { OrderDateType } from "@ero/app-common/models";
import { EventResponseBody } from "@ero/app-common/v2/routes/models/event";
import {
  EventChangeArg,
  EventClickArg,
  EventContentArg,
  EventInput,
} from "@fullcalendar/core";
import {
  EventDragStartArg,
  EventDragStopArg,
  EventReceiveArg,
  EventResizeStartArg,
} from "@fullcalendar/interaction";
import { useTheme } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useEventDraggingContext } from "Screens/planningV2/contexts/EventDraggingContext";
import { getBusinessHours } from "Screens/planningV2/utils/eventsOrderCollisionInfo";
import { errorToast } from "Services";
import { AppState } from "Store";
import { sagaActions } from "Store/planningV2/planningV2SagaActions";
import { getOrderStatusColor } from "Utils";
import { Event } from "../components/event/event";

export const useEvents = (eventClickCallback: (id: number) => void) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const {
    date: currentCalendarDate,
    loadingEvents,
    events,
    highlightedEvent,
  } = useSelector((store: AppState) => store.planningV2);

  const { setActive, setInactive } = useEventDraggingContext();

  const [t] = useTranslation();

  const [selectedEvents, setSelectedEvents] = useState<number[]>([]);

  const calendarEvents = useMemo(
    () =>
      events.map((event): EventInput => {
        const color =
          getOrderStatusColor(event.status).split(".")[1] ?? "default";

        return {
          id: `${event._id}`,
          title: `${event.order?.name}`,
          resourceId: `${event.user}`,
          start: event.start,
          end: event.end,
          groupId: selectedEvents.includes(event._id)
            ? "selection"
            : `${event._id}`,
          editable: !event.locked,
          startEditable: !event.locked,
          resourceEditable: !event.locked,
          extendedProps: event,
          classNames:
            highlightedEvent === event._id ? "highlighted-event" : undefined,
          backgroundColor: theme.palette.status[color],
          borderColor: theme.palette.status[color],
        };
      }),
    [events, selectedEvents, highlightedEvent, theme.palette],
  );

  const eventChangeStart = useCallback(
    (eventId: string, orderId: number, dateRestrictions: OrderDateType) => {
      const eventsBusinessHours = getBusinessHours(
        currentCalendarDate,
        dateRestrictions,
      );
      setActive(orderId, eventsBusinessHours);
      setSelectedEvents((curr) => {
        if (curr.includes(Number(eventId))) {
          return curr;
        }
        return [];
      });
    },
    [currentCalendarDate, setActive],
  );

  const eventChangeEnd = useCallback(() => {
    setInactive();
  }, [setInactive]);

  const eventDragStart = useCallback(
    (arg: EventDragStartArg) => {
      eventChangeStart(
        arg.event.id,
        arg.event.extendedProps.order._id,
        arg.event.extendedProps.order.dateRestrictions,
      );

      const orderListContainer = document.getElementById("orderListContainer");
      if (orderListContainer) {
        orderListContainer.style.display = "none";
      }
      const dropzoneContainer = document.getElementById("dropzoneContainer");
      if (dropzoneContainer) {
        dropzoneContainer.style.display = "block";
      }
    },
    [eventChangeStart],
  );

  const eventDragStop = useCallback(
    (arg: EventDragStopArg): void => {
      eventChangeEnd();
      if (
        arg.jsEvent.target instanceof HTMLElement &&
        arg.jsEvent.target.closest("#dropzone")
      ) {
        const eventId = Number(arg.event.id);
        const deleteIds = selectedEvents.includes(eventId)
          ? selectedEvents
          : [eventId];
        dispatch(sagaActions.deleteEvent({ ids: deleteIds }));
      }

      const orderListContainer = document.getElementById("orderListContainer");
      if (orderListContainer) {
        orderListContainer.style.display = "block";
      }
      const dropzoneContainer = document.getElementById("dropzoneContainer");
      if (dropzoneContainer) {
        dropzoneContainer.style.display = "none";
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedEvents, setInactive],
  );

  const eventResizeStart = useCallback(
    (arg: EventResizeStartArg): void => {
      eventChangeStart(
        arg.event.id,
        arg.event.extendedProps.order._id,
        arg.event.extendedProps.order.dateRestrictions,
      );
    },
    [eventChangeStart],
  );

  const eventResizeStop = useCallback((): void => {
    eventChangeEnd();
  }, [eventChangeEnd]);

  const eventReceive = useCallback(
    (arg: EventReceiveArg) => {
      setSelectedEvents([]);
      if (arg.event.start && arg.event.end) {
        const user = Number(arg.event.getResources()[0].id);
        if (arg.event.extendedProps._id) {
          // sometimes FC erroneously seems to call this method instead of eventDragEnd
          // in this case, we have to update the event instead of creating a false new one.
          dispatch(
            sagaActions.updateEvent({
              id: arg.event.extendedProps._id,
              update: {
                start: arg.event.start.getTime(),
                end: arg.event.end.getTime(),
                user,
              },
            }),
          );
        } else {
          dispatch(
            sagaActions.addEvent({
              event: {
                order: Number(arg.event.id),
                jobs: arg.event.extendedProps.order.jobs,
                start: arg.event.start.getTime(),
                end: arg.event.end.getTime(),
                user,
              },
              revert: arg.revert,
            }),
          );
        }
      } else {
        arg.revert();
        errorToast(t("errors.unableToAddEvent"));
      }
      setInactive();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setInactive, t],
  );

  const eventChange = useCallback(
    (arg: EventChangeArg) => {
      const calendarEventsForUpdate = [arg.event, ...arg.relatedEvents];
      dispatch(
        sagaActions.updateEvents({
          events: calendarEventsForUpdate.map((event) => ({
            id: Number(event.id),
            update: {
              start: event.start?.getTime(),
              end: event.end?.getTime(),
              user: Number(event.getResources()[0].id),
            },
          })),
          revert: arg.revert,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const eventClick = useCallback(
    (arg: EventClickArg) => {
      setSelectedEvents((curr) => {
        const event = arg.event.extendedProps as EventResponseBody;
        if (event.locked) {
          if (event.order) {
            eventClickCallback(event.order._id);
          }
          return curr;
        }

        const eventId = Number(arg.event.id);
        if (!arg.jsEvent.ctrlKey && !curr.includes(eventId)) {
          if (event.order) {
            eventClickCallback(event.order._id);
          }
          return [];
        }
        if (arg.jsEvent.ctrlKey) {
          const index = curr.findIndex((id) => eventId === id);
          if (index !== -1) {
            return curr.toSpliced(index, 1);
          } else {
            return [...curr, eventId];
          }
        }
        return curr;
      });
    },
    [eventClickCallback],
  );

  const getEventContent = useCallback(
    (arg: EventContentArg) => {
      const event = arg.event.extendedProps as EventResponseBody;
      return (
        <Event
          start={arg.event.start}
          end={arg.event.end}
          event={event}
          loading={loadingEvents}
          selected={selectedEvents.includes(event._id)}
        />
      );
    },
    [loadingEvents, selectedEvents],
  );

  const result = useMemo(
    () => ({
      calendarEvents,
      eventDragStart,
      eventDragStop,
      eventResizeStart,
      eventResizeStop,
      eventReceive,
      eventChange,
      eventClick,
      getEventContent,
    }),
    [
      calendarEvents,
      eventChange,
      eventClick,
      eventDragStart,
      eventDragStop,
      eventReceive,
      eventResizeStart,
      eventResizeStop,
      getEventContent,
    ],
  );

  return result;
};
