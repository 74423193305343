import { PERM } from "@ero/app-common/enums";
import {
  EventResponseBody,
  EventsResponseBody,
} from "@ero/app-common/v2/routes/models/event";
import {
  OrderResponseBody,
  OrdersRequestQuery,
  OrdersResponseBody,
} from "@ero/app-common/v2/routes/models/order";
import { createOrder, getEmployeesV2, getOrdersV2Slim } from "Api";
import { addEvent, deleteEvent, getEvents, updateEvent } from "Api/events";
import dayjs from "dayjs";
import i18n from "i18n/i18n";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { errorToast, successToast } from "Services";
import { AppState } from "Store/store";
import { getStartAndEndDay } from "Utils";
import { sagaActions } from "./planningV2SagaActions";
import { planningV2Slice } from "./planningV2slice";

function* fetchUsersSaga(): Generator<any, void, any> {
  try {
    yield put(planningV2Slice.actions.setLoadingUsers(true));
    const usersResponse = yield call(getEmployeesV2, {
      type: PERM.DRIVER,
      includeTrack: false,
      limit: 99999,
    });
    yield put(planningV2Slice.actions.setUsers(usersResponse.data));
    if (usersResponse.data.length === 1) {
      const userId = usersResponse.data[0]._id;
      yield put(sagaActions.setSelectedUsers([userId]));
    }
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadUsers"), undefined, error);
    yield put(planningV2Slice.actions.setLoadingUsers(false));
  }
}

function* setSelectedUsersSaga(
  action: ReturnType<typeof sagaActions.setSelectedUsers>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setSelectedUsers(action.payload));
  // refetch events to force re-rendering of events
  // (reorder layout if event is not wide enough)
  yield put(sagaActions.fetchEvents());
}

function getFilterOptions(orders: OrderResponseBody[]) {
  const rawFilterOptions = orders
    .map((order) => ({
      customer: order.customer,
      services: order.jobDetails?.services || [],
      grapeVarieties: order.jobDetails?.grapeVarieties || [],
      gemarkungen: order.jobDetails?.gemarkungen || [],
      regionettes: order.jobDetails?.regionettes || [],
    }))
    .filter((f) => f !== undefined)
    .flat();
  const customers = rawFilterOptions
    .map((f) => f.customer)
    .filter(
      (customer, index, customers) =>
        customers.findIndex((c) => c._id === customer._id) === index,
    )
    .map((customer) => ({
      label: customer.companyName,
      value: customer._id,
    }));
  const services = [
    ...new Set(rawFilterOptions.map((f) => f.services).flat()),
  ].map((service) => ({ label: service, value: service }));
  const grapeVarieties = rawFilterOptions
    .map((f) => f.grapeVarieties)
    .flat()
    .filter(
      (grapeVariety, index, grapeVarieties) =>
        grapeVarieties.findIndex((g) => g._id === grapeVariety._id) === index,
    )
    .map((grapeVariety) => ({
      label: grapeVariety.name,
      value: grapeVariety._id,
    }));
  const grapeVarietyColors = rawFilterOptions
    .map((f) => f.grapeVarieties)
    .flat()
    .filter(
      (grapeVariety, index, grapeVarieties) =>
        grapeVarieties.findIndex((c) => c.color === grapeVariety.color) ===
        index,
    )
    .map((grapeVariety) => ({
      label: i18n.t("parcels.kind." + grapeVariety.color),
      value: grapeVariety.color,
    }));
  const regionettes = [
    ...new Set(rawFilterOptions.map((f) => f.regionettes).flat()),
  ].map((regionette) => ({ label: regionette, value: regionette }));
  const gemarkungen = [
    ...new Set(rawFilterOptions.map((f) => f.gemarkungen).flat()),
  ].map((mark) => ({ label: mark, value: mark }));

  return {
    customers,
    services,
    grapeVarieties,
    grapeVarietyColors,
    regionettes,
    gemarkungen,
  };
}

function* fetchOrdersSaga(): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setLoadingOrders(true));

  const { date, filters, hasFilters } = yield select(
    (state: AppState) => state.planningV2,
  );
  const start = dayjs(date).startOf("day").unix() * 1000;
  const end = dayjs(date).endOf("day").unix() * 1000;
  try {
    const query: OrdersRequestQuery = {
      limit: 999999,
      sortBy: ["date"],
      ordersDateRange: { start, end },
      jobsDateRange: { start: -1, end: -1 },
      ...filters,
    };
    const ordersResponse = yield call(getOrdersV2Slim, query);
    const ordersWithJobs = ordersResponse.data.filter(
      (order: OrdersResponseBody["data"][0]) => order.jobDetails !== undefined,
    );
    yield put(
      planningV2Slice.actions.setOrders({
        orders: ordersWithJobs,
      }),
    );

    if (!hasFilters) {
      const filtersOptions = yield call(getFilterOptions, ordersResponse.data);
      yield put(planningV2Slice.actions.setFiltersOptions(filtersOptions));
    }
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadOrders"), undefined, error);
  } finally {
    yield put(planningV2Slice.actions.setLoadingOrders(false));
  }
}

function* fetchEventsSaga(): Generator<any, void, any> {
  const calendarDate = yield select((state: AppState) => state.planningV2.date);
  const start = dayjs(calendarDate).startOf("day").unix() * 1000;
  const end = dayjs(calendarDate).endOf("day").unix() * 1000;
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));
    const eventResponse = yield call(getEvents, { limit: 99999, start, end });
    yield put(planningV2Slice.actions.setEvents(eventResponse.data));
  } catch (error) {
    errorToast(i18n.t("errors.unableToLoadEvents"), undefined, error);
    yield put(planningV2Slice.actions.setLoadingEvents(false));
  }
}

function* addEventSaga(
  action: ReturnType<typeof sagaActions.addEvent>,
): Generator<any, void, any> {
  try {
    const newEvent = yield call(addEvent, action.payload.event);
    const events = yield select((state: AppState) => state.planningV2.events);

    const highlightedOrder = yield select(
      (state: AppState) => state.planningV2.highlightedOrder,
    );

    yield put(planningV2Slice.actions.setEvents([...events, newEvent]));

    if (newEvent.order._id === highlightedOrder) {
      yield put(planningV2Slice.actions.setHighlightedOrder(undefined));
    }
    yield put(sagaActions.fetchOrders());
  } catch (error) {
    errorToast(i18n.t("errors.unableToAddEvent"), undefined, error);
    action.payload.revert();
  }
}

function* updateEventSaga(
  action: ReturnType<typeof sagaActions.updateEvent>,
): Generator<any, void, any> {
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));
    const updatedEvent = yield call(
      updateEvent,
      action.payload.id,
      action.payload.update,
    );
    const events = yield select((state: AppState) => state.planningV2.events);
    const updateIndex = events.findIndex(
      (event) => event._id === updatedEvent._id,
    );
    const updatedEvents = [...events];
    updatedEvents[updateIndex] = updatedEvent;
    yield put(planningV2Slice.actions.setEvents(updatedEvents));
  } catch (error) {
    yield put(planningV2Slice.actions.setLoadingEvents(false));
    errorToast(i18n.t("errors.unableToUpdateEvent"), undefined, error);
    action.payload.revert?.();
  }
}

function* updateEventsSaga(
  action: ReturnType<typeof sagaActions.updateEvents>,
): Generator<any, void, any> {
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));

    const updatedEvents: EventResponseBody[] = yield all(
      action.payload.events.map(({ id, update }) =>
        call(updateEvent, id, update),
      ),
    );

    const events: EventsResponseBody["data"] = yield select(
      (state: AppState) => state.planningV2.events,
    );

    const newEvents = [
      ...events.map((event) => {
        return (
          updatedEvents.find(
            (updatedEvent) => updatedEvent._id === event._id,
          ) ?? event
        );
      }),
    ];

    yield put(planningV2Slice.actions.setEvents(newEvents));
  } catch (error) {
    yield put(planningV2Slice.actions.setLoadingEvents(false));
    errorToast(i18n.t("errors.unableToUpdateEvent"), undefined, error);
    action.payload.revert();
  }
}

function* deleteEventSaga(
  action: ReturnType<typeof sagaActions.deleteEvent>,
): Generator<any, void, EventsResponseBody["data"]> {
  try {
    yield put(planningV2Slice.actions.setLoadingEvents(true));

    yield all(action.payload.ids.map((id) => call(deleteEvent, id)));
    const events = yield select((state: AppState) => state.planningV2.events);
    const newEvents = events.filter(
      (event) => !action.payload.ids.includes(event._id),
    );
    yield put(planningV2Slice.actions.setEvents(newEvents));
    successToast(i18n.t("planningV2.successfullyRemoved"));
    yield put(sagaActions.fetchOrders());
  } catch (error) {
    errorToast(i18n.t("errors.unableToDeleteEvent"), undefined, error);
    yield put(planningV2Slice.actions.setLoadingEvents(false));
  }
}

function* setDateSaga(
  action: ReturnType<typeof sagaActions.setDate>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setHighlightedEvent(undefined));

  yield put(
    planningV2Slice.actions.setHighlightedOrder(
      action.payload.highlightOrderId,
    ),
  );

  yield put(planningV2Slice.actions.setDate(action.payload.date));
  yield put(
    sagaActions.setFilters({
      customer: [],
      grapeVariety: [],
      grapeVarietyColor: undefined,
      mark: [],
      regionette: [],
      service: [],
    }),
  );

  yield put(sagaActions.fetchOrders());
  yield put(sagaActions.fetchEvents());
}

function* setFilters(
  action: ReturnType<typeof sagaActions.setFilters>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setFilters(action.payload));
  yield put(sagaActions.fetchOrders());
}

function* setSlotInterval(
  action: ReturnType<typeof sagaActions.setSlotInterval>,
): Generator<any, void, any> {
  yield put(planningV2Slice.actions.setSlotInterval(action.payload));
  // refetch events to force re-rendering of events
  // (show tooltips if event length is rather short)
  yield put(sagaActions.fetchEvents());
}

function* createOrderSaga(action: ReturnType<typeof sagaActions.createOrder>) {
  try {
    yield put(planningV2Slice.actions.setLoadingOrders(true));

    yield put(planningV2Slice.actions.setHighlightedOrder(undefined));

    const createdOrder = yield call(createOrder, action.payload);
    yield put(
      planningV2Slice.actions.setRecentlyCreatedOrderId(createdOrder._id),
    );

    yield put(planningV2Slice.actions.setHighlightedOrder(createdOrder._id));

    yield put(sagaActions.fetchOrders());
  } catch (error) {
    errorToast(i18n.t("errors.unableToSaveOrder"), undefined, error);
  } finally {
    yield put(planningV2Slice.actions.setLoadingOrders(false));
  }
}

function* prepareEventRevalSaga(
  action: ReturnType<typeof sagaActions.prepareEventReveal>,
) {
  const { job, order, jobEvent } = action.payload;
  if (job.start !== -1 && job.end !== -1) {
    yield put(planningV2Slice.actions.setDate(job.start));
    yield put(planningV2Slice.actions.setHighlightedEvent(jobEvent?._id));
    yield put(planningV2Slice.actions.setScrollTime(jobEvent?.start));
  } else {
    const newCalendarDate = getStartAndEndDay(order.dateRestrictions)?.start;
    if (newCalendarDate) {
      yield put(planningV2Slice.actions.setDate(newCalendarDate.unix() * 1000));
    }
    yield put(planningV2Slice.actions.setHighlightedOrder(order._id));
  }
}

function* resetHighlightsSaga() {
  yield put(planningV2Slice.actions.setHighlightedEvent(undefined));
  yield put(planningV2Slice.actions.setHighlightedOrder(undefined));
}

export default function* planningV2Saga() {
  yield takeLatest(sagaActions.fetchUsers.type, fetchUsersSaga);
  yield takeLatest(sagaActions.setSelectedUsers.type, setSelectedUsersSaga);
  yield takeLatest(sagaActions.fetchOrders.type, fetchOrdersSaga);
  yield takeLatest(sagaActions.fetchEvents.type, fetchEventsSaga);
  yield takeLatest(sagaActions.setDate.type, setDateSaga);
  yield takeLatest(sagaActions.addEvent.type, addEventSaga);
  yield takeLatest(sagaActions.updateEvent.type, updateEventSaga);
  yield takeLatest(sagaActions.updateEvents.type, updateEventsSaga);
  yield takeLatest(sagaActions.deleteEvent.type, deleteEventSaga);
  yield takeLatest(sagaActions.setFilters.type, setFilters);
  yield takeLatest(sagaActions.setSlotInterval.type, setSlotInterval);
  yield takeLatest(sagaActions.createOrder.type, createOrderSaga);
  yield takeLatest(sagaActions.prepareEventReveal.type, prepareEventRevalSaga);
  yield takeLatest(sagaActions.resetHighlights.type, resetHighlightsSaga);
}
