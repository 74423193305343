import { Grid2, Typography } from "@mui/material";
import React, { useMemo } from "react";

type EnumeratedStringProps = {
  strings: string[] | undefined;
};

export const EnumeratedString: React.FC<EnumeratedStringProps> = ({
  strings,
}) => {
  const enumeratedText = useMemo(() => {
    let joined = strings?.slice(0, 3).join(", ") ?? "";
    if (strings !== undefined && strings?.length > 3) {
      joined += ` + ${strings.length - 3}`;
    }
    return joined;
  }, [strings]);

  return (
    <Grid2 size={12}>
      <Typography variant="caption">{enumeratedText}</Typography>
    </Grid2>
  );
};
