import {
  RequestBody,
  type GetTableDataParams,
  type GetTableEntitiesType,
  type SetTableEntityType,
} from "Types";
import {
  EMPLOYEES_ACTION_TYPES,
  type IChangeColumnsOrder,
  type ICreateEmployeeRequestAction,
  type IDeleteEmployeesRequestAction,
  type IGetEmployeesRequestAction,
  type IGetEmployeesSuccessAction,
  type IGetInitialEmployeesErrorAction,
  type IGetInitialEmployeesRequestAction,
  type IGetInitialEmployeesSuccessAction,
  type IResetMetaAction,
  type IResetSearchAction,
  type ISetEditPasswordSuccessAction,
  type ISetErrorAction,
  type ISetLoadingAction,
  type ISetSuccessAction,
  type ISetTableHiddenColumns,
  type IUpdateEmployeePassword,
  type IUpdateEmployeeRequestAction,
} from "./action-types";
import { type LocalEmployeeType } from "./specs";

export const setLoading = (): ISetLoadingAction => ({
  type: EMPLOYEES_ACTION_TYPES.SET_LOADING,
});

export const setSuccess = (): ISetSuccessAction => ({
  type: EMPLOYEES_ACTION_TYPES.SET_SUCCESS,
});

export const setError = (): ISetErrorAction => ({
  type: EMPLOYEES_ACTION_TYPES.SET_ERROR,
});

export const resetMeta = (): IResetMetaAction => ({
  type: EMPLOYEES_ACTION_TYPES.RESET_META,
});

export const resetSearch = (): IResetSearchAction => ({
  type: EMPLOYEES_ACTION_TYPES.RESET_SEARCH,
});

export const getEmployeesInitial = (
  params: GetTableDataParams,
): IGetInitialEmployeesRequestAction => ({
  type: EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_REQUEST,
  payload: {
    params,
  },
});

export const getEmployeesInitialSuccess = (
  employees: LocalEmployeeType[],
  maxCount: number,
): IGetInitialEmployeesSuccessAction => ({
  type: EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_SUCCESS,
  payload: {
    employees,
    maxCount,
  },
});

export const getEmployeesInitialError =
  (): IGetInitialEmployeesErrorAction => ({
    type: EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_ERROR,
  });

export const getEmployees = ({
  params,
  search,
  filters,
}: GetTableEntitiesType): IGetEmployeesRequestAction => ({
  type: EMPLOYEES_ACTION_TYPES.GET_EMPLOYEES_REQUEST,
  payload: {
    params,
    search,
    filters,
  },
});

export const getEmployeesSuccess = ({
  list,
  maxCount,
  listMeta,
  search,
  filters,
}: SetTableEntityType<LocalEmployeeType>): IGetEmployeesSuccessAction => ({
  type: EMPLOYEES_ACTION_TYPES.GET_EMPLOYEES_SUCCESS,
  payload: {
    employees: list,
    maxCount,
    listMeta,
    search,
    filters,
  },
});

export const createEmployee = (
  employee: RequestBody<"/persons/invite">,
): ICreateEmployeeRequestAction => ({
  type: EMPLOYEES_ACTION_TYPES.CREATE_EMPLOYEE_REQUEST,
  payload: {
    employee,
  },
});

export const updateEmployee = (
  employee: { [name: string]: string },
  id: number,
): IUpdateEmployeeRequestAction => ({
  type: EMPLOYEES_ACTION_TYPES.UPDATE_EMPLOYEE_REQUEST,
  payload: {
    employee,
    id,
  },
});

export const deleteEmployees = (ids: {
  ids: number[];
}): IDeleteEmployeesRequestAction => ({
  type: EMPLOYEES_ACTION_TYPES.DELETE_EMPLOYEE_REQUEST,
  payload: {
    ids,
  },
});

export const setTableHiddenColumns = (
  hiddenColumns: Record<string, boolean>,
): ISetTableHiddenColumns => ({
  type: EMPLOYEES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS,
  payload: { hiddenColumns },
});

export const changeColumnsOrder = (order: number[]): IChangeColumnsOrder => ({
  type: EMPLOYEES_ACTION_TYPES.CHANGE_COLUMNS_ORDER,
  payload: { order },
});

export const updateEmployeePassword = (
  id: number,
  password: string,
): IUpdateEmployeePassword => ({
  type: EMPLOYEES_ACTION_TYPES.UPDATE_PASSWORD,
  payload: { id, password },
});

export const setEditPasswordSuccess = (): ISetEditPasswordSuccessAction => ({
  type: EMPLOYEES_ACTION_TYPES.SET_EDIT_PASSWORD_SUCCESS,
});
