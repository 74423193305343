import { EventsResponseBody } from "@ero/app-common/v2/routes/models/event";
import { OrdersResponseBody } from "@ero/app-common/v2/routes/models/order";
import { UsersResponseBody } from "@ero/app-common/v2/routes/models/user";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CalendarSlotDuration } from "Enums";
import { OptionType } from "Types";

export type FilterType = {
  customer: string[];
  service: string[];
  grapeVariety: string[];
  grapeVarietyColor: string | undefined;
  regionette: string[];
  mark: string[];
};

export type FilterOptions = {
  customers: OptionType[];
  services: OptionType[];
  grapeVarieties: OptionType[];
  grapeVarietyColors: OptionType[];
  regionettes: OptionType[];
  gemarkungen: OptionType[];
};

type PlanningV2State = {
  date: number;
  users: UsersResponseBody["data"];
  loadingUsers: boolean;
  selectedUsers: number[] | undefined;
  sortedUsers: number[];
  orders: OrdersResponseBody<number[]>["data"];
  recentlyCreatedOrderId: number | undefined;
  loadingOrders: boolean;
  events: EventsResponseBody["data"];
  loadingEvents: boolean;
  filters: FilterType;
  hasFilters: boolean;
  filtersOptions: FilterOptions;
  slotDuration: CalendarSlotDuration;
  highlightedOrder: number | undefined;
  highlightedEvent: number | undefined;
  scrollTime: number | undefined;
};

const initialState: PlanningV2State = {
  date: Date.now(),
  users: [],
  loadingUsers: false,
  selectedUsers: undefined,
  sortedUsers: [],
  orders: [],
  recentlyCreatedOrderId: undefined,
  loadingOrders: false,
  events: [],
  loadingEvents: false,
  filters: {
    customer: [],
    grapeVariety: [],
    grapeVarietyColor: undefined,
    mark: [],
    regionette: [],
    service: [],
  },
  hasFilters: false,
  filtersOptions: {
    customers: [],
    services: [],
    grapeVarieties: [],
    grapeVarietyColors: [],
    regionettes: [],
    gemarkungen: [],
  },
  slotDuration: CalendarSlotDuration.FIVETEEN_MINUTES,
  highlightedOrder: undefined,
  highlightedEvent: undefined,
  scrollTime: undefined,
};

type SetLoadingUsersAction = PayloadAction<boolean>;
type SetUsersAction = PayloadAction<UsersResponseBody["data"]>;
type SetSelectedUsersAction = PayloadAction<number[]>;
type SetSortedUsersAction = PayloadAction<number[]>;
type SetDateAction = PayloadAction<number>;
type SetOrdersAction = PayloadAction<{
  orders: OrdersResponseBody<number[]>["data"];
}>;
type SetLoadingOrdersAction = PayloadAction<boolean>;
type SetEventsAction = PayloadAction<EventsResponseBody["data"]>;
type SetLoadingEventsAction = PayloadAction<boolean>;
type SetFiltersAction = PayloadAction<FilterType>;
type SetFiltersOptionsAction = PayloadAction<FilterOptions>;
type SetSlotDurationAction = PayloadAction<CalendarSlotDuration>;
type SetHighlightedOrderAction = PayloadAction<number | undefined>;
type SetHighlightedEventAction = PayloadAction<number | undefined>;
type SetScrollTimeAction = PayloadAction<number | undefined>;

export const planningV2Slice = createSlice({
  name: "planningV2",
  initialState,
  reducers: {
    setLoadingUsers: (state, action: SetLoadingUsersAction) => {
      state.loadingUsers = action.payload;
    },
    setUsers: (state, action: SetUsersAction) => {
      state.users = action.payload;
      state.loadingUsers = false;
    },
    setSelectedUsers: (state, action: SetSelectedUsersAction) => {
      state.selectedUsers = action.payload;
    },
    setSortedUsers: (state, action: SetSortedUsersAction) => {
      state.sortedUsers = action.payload;
    },
    setDate: (state, action: SetDateAction) => {
      state.date = action.payload;
    },
    setLoadingOrders: (state, action: SetLoadingOrdersAction) => {
      state.loadingOrders = action.payload;
    },
    setOrders: (state, action: SetOrdersAction) => {
      state.orders = action.payload.orders;
      state.loadingOrders = false;
    },
    setRecentlyCreatedOrderId: (
      state,
      action: PayloadAction<number | undefined>,
    ) => {
      state.recentlyCreatedOrderId = action.payload;
    },
    setLoadingEvents: (state, action: SetLoadingEventsAction) => {
      state.loadingEvents = action.payload;
    },
    setEvents: (state, action: SetEventsAction) => {
      state.events = action.payload;
      state.loadingEvents = false;
    },
    setFilters: (state, action: SetFiltersAction) => {
      state.filters = action.payload;
      state.hasFilters =
        Object.keys(action.payload).reduce((curr, key) => {
          if (key === "grapeVarietyColor" && key === undefined) {
            return curr++;
          } else {
            return curr + (action.payload[key]?.length ?? 0);
          }
        }, 0) > 0;
    },
    setFiltersOptions: (state, action: SetFiltersOptionsAction) => {
      state.filtersOptions = action.payload;
    },
    setSlotInterval: (state, action: SetSlotDurationAction) => {
      state.slotDuration = action.payload;
    },
    setHighlightedOrder: (state, action: SetHighlightedOrderAction) => {
      state.highlightedOrder = action.payload;
    },
    setHighlightedEvent: (state, action: SetHighlightedEventAction) => {
      state.highlightedEvent = action.payload;
    },
    setScrollTime: (state, action: SetScrollTimeAction) => {
      state.scrollTime = action.payload;
    },
  },
});
