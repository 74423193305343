import { type SearchSpec } from "@ero/app-common/util/SearchSpec";
import {
  RequestBody,
  type ColumnFilterDataType,
  type GetTableDataParams,
} from "Types";
import { type Action } from "redux";
import { type LocalEmployeeType } from "./specs";

export enum EMPLOYEES_ACTION_TYPES {
  GET_INITIAL_EMPLOYEES_REQUEST = "employees/get-initial-employees-request",
  GET_INITIAL_EMPLOYEES_SUCCESS = "employees/get-initial-employees-success",
  GET_INITIAL_EMPLOYEES_ERROR = "employees/get-initial-employees-error",
  GET_EMPLOYEES_REQUEST = "employees/get-employees-request",
  GET_EMPLOYEES_SUCCESS = "employees/get-employees-success",
  CREATE_EMPLOYEE_REQUEST = "employees/create-employee-request",
  CREATE_EMPLOYEE_SUCCESS = "employees/create-employee-success",
  UPDATE_EMPLOYEE_REQUEST = "employee/update-employee-request",
  UPDATE_EMPLOYEE_SUCCESS = "employee/update-employee-success",
  DELETE_EMPLOYEE_REQUEST = "employee/delete-employee-request",
  DELETE_EMPLOYEE_SUCCESS = "employee/delete-employee-success",
  SET_LOADING = "employees/set-loading",
  SET_SUCCESS = "employees/set-success",
  RESET_META = "employees/reset-meta",
  RESET_SEARCH = "employees/reset-search",
  SET_ERROR = "employees/set-error",
  SET_TABLE_HIDDEN_COLUMNS = "employees/set-table-hidden-columns",
  CHANGE_COLUMNS_ORDER = "employees/change-columns-order",
  UPDATE_PASSWORD = "employees/update-password",
  UPDATE_EMPLOYEE_LIVE = "employee/update-employee-request-live",
  SET_EDIT_PASSWORD_SUCCESS = "employees/set-edit-password-success",
}

export interface ISetLoadingAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.SET_LOADING;
}

export interface ISetSuccessAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.SET_SUCCESS;
}

export interface ISetErrorAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.SET_ERROR;
}

export interface IResetMetaAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.RESET_META;
}

export interface IResetSearchAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.RESET_SEARCH;
}

export interface IGetInitialEmployeesRequestAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_REQUEST;
  payload: {
    params: GetTableDataParams;
  };
}
export interface IGetInitialEmployeesSuccessAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_SUCCESS;
  payload: {
    employees: LocalEmployeeType[];
    maxCount: number;
  };
}

export interface IGetInitialEmployeesErrorAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_ERROR;
}

export interface IGetEmployeesRequestAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.GET_EMPLOYEES_REQUEST;
  payload: {
    params: GetTableDataParams;
    search?: SearchSpec;
    filters?: ColumnFilterDataType[];
  };
}

export interface IGetEmployeesSuccessAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.GET_EMPLOYEES_SUCCESS;
  payload: {
    employees: LocalEmployeeType[];
    maxCount: number;
    listMeta: GetTableDataParams;
    search?: SearchSpec;
    filters?: ColumnFilterDataType[];
  };
}

export interface ICreateEmployeeRequestAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.CREATE_EMPLOYEE_REQUEST;
  payload: {
    employee: RequestBody<"/persons/invite">;
  };
}

export interface IUpdateEmployeeRequestAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.UPDATE_EMPLOYEE_REQUEST;
  payload: {
    employee: { [name: string]: string };
    id: number;
  };
}

export interface IDeleteEmployeesRequestAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.DELETE_EMPLOYEE_REQUEST;
  payload: {
    ids: {
      ids: number[];
    };
  };
}

export interface ISetTableHiddenColumns extends Action {
  type: EMPLOYEES_ACTION_TYPES.SET_TABLE_HIDDEN_COLUMNS;
  payload: { hiddenColumns: Record<string, boolean> };
}

export interface IUpdateEmployeePassword extends Action {
  type: EMPLOYEES_ACTION_TYPES.UPDATE_PASSWORD;
  payload: { id: number; password: string };
}

export interface IChangeColumnsOrder extends Action {
  type: EMPLOYEES_ACTION_TYPES.CHANGE_COLUMNS_ORDER;
  payload: { order: number[] };
}

export interface ISetEditPasswordSuccessAction extends Action {
  type: EMPLOYEES_ACTION_TYPES.SET_EDIT_PASSWORD_SUCCESS;
}

export type EmployeesAction =
  | ISetLoadingAction
  | ISetErrorAction
  | ISetSuccessAction
  | IResetMetaAction
  | IResetSearchAction
  | IGetEmployeesRequestAction
  | IGetEmployeesSuccessAction
  | IGetInitialEmployeesRequestAction
  | IGetInitialEmployeesSuccessAction
  | IGetInitialEmployeesErrorAction
  | ICreateEmployeeRequestAction
  | IUpdateEmployeeRequestAction
  | IDeleteEmployeesRequestAction
  | ISetTableHiddenColumns
  | IChangeColumnsOrder
  | IUpdateEmployeePassword
  | ISetEditPasswordSuccessAction;
