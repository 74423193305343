import { type spec_shape } from "@ero/app-common/routes/validators";
import {
  changeEmployeePassword,
  createEmployee,
  deleteEmployees,
  getEmployees,
  updateEmployee,
} from "Api";
import { errorToast, successToast } from "Services";
import { getTableParamsAfterDeletion } from "Utils";
import i18n from "i18next";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import z from "zod";
import { getUserData } from "../auth";
import {
  EMPLOYEES_ACTION_TYPES,
  type ICreateEmployeeRequestAction,
  type IDeleteEmployeesRequestAction,
  type IGetEmployeesRequestAction,
  type IGetInitialEmployeesRequestAction,
  type IUpdateEmployeePassword,
  type IUpdateEmployeeRequestAction,
} from "./action-types";
import {
  getEmployeesInitialError,
  getEmployeesInitialSuccess,
  getEmployeesSuccess,
  resetMeta,
  setEditPasswordSuccess,
  setError,
  setLoading,
  setSuccess,
} from "./actions";
import { employeesSpec as employeesSpec_ } from "./specs";
const employeesSpec = employeesSpec_ as unknown as z.infer<typeof spec_shape>;

export function* getInitialEmployeesSaga({
  payload,
}: IGetInitialEmployeesRequestAction) {
  const { params } = payload;

  try {
    const store = yield select();

    const { data } = yield call(getEmployees, {
      params,
      search: store.employees.searchData,
      filters: store.employees.filters,
      spec: employeesSpec,
    });

    yield put(getEmployeesInitialSuccess(data.data, data.maxCount));
  } catch (error) {
    yield put(getEmployeesInitialError());

    errorToast(undefined, undefined, error);
  }
}

export function* getEmployeesSaga({ payload }: IGetEmployeesRequestAction) {
  const { params, search, filters } = payload;

  try {
    const { data } = yield call(getEmployees, {
      params,
      search,
      filters,
      spec: employeesSpec,
    });

    yield put(
      getEmployeesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: params,
        search,
        filters,
      }),
    );
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* createEmployeeSaga({ payload }: ICreateEmployeeRequestAction) {
  const { employee } = payload;

  try {
    yield put(setLoading());

    yield call(createEmployee, employee);

    const store = yield select();

    const { data } = yield call(getEmployees, {
      params: store.employees.listMeta,
      search: store.employees.searchData,
      filters: store.employees.filters,
      spec: employeesSpec,
    });

    yield put(
      getEmployeesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: store.employees.listMeta,
        search: store.employees.searchData,
        filters: store.employees.filters,
      }),
    );

    yield put(setSuccess());

    if (employee.email && !employee.password) {
      successToast(
        i18n.t("notification.createEmployeeWithMail", {
          email: employee.email,
        }),
      );
    } else {
      successToast(i18n.t("notification.createEmployee"));
    }
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* updateEmployeeSaga({ payload }: IUpdateEmployeeRequestAction) {
  const { employee, id } = payload;

  try {
    yield put(setLoading());

    yield call(updateEmployee, employee, id);

    const store = yield select();

    const { data } = yield call(getEmployees, {
      params: store.employees.listMeta,
      search: store.employees.searchData,
      filters: store.employees.filters,
      spec: employeesSpec,
    });

    yield put(
      getEmployeesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta: store.employees.listMeta,
        search: store.employees.searchData,
        filters: store.employees.filters,
      }),
    );

    if (store.auth.userData._id === id) {
      yield put(getUserData());
    }

    yield put(setSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* deleteEmployeeSaga({
  payload,
}: IDeleteEmployeesRequestAction) {
  const { ids } = payload;

  try {
    yield put(setLoading());

    yield call(deleteEmployees, ids);

    const store = yield select();

    const listMeta = getTableParamsAfterDeletion(
      store.employees.listMeta,
      store.employees.list.length,
      ids.ids.length,
    );

    const { data } = yield call(getEmployees, {
      params: listMeta,
      search: store.employees.searchData,
      filters: store.employees.filters,
      spec: employeesSpec,
    });

    yield put(
      getEmployeesSuccess({
        list: data.data,
        maxCount: data.maxCount,
        listMeta,
        search: store.employees.searchData,
        filters: store.employees.filters,
      }),
    );

    yield put(setSuccess());

    yield put(resetMeta());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export function* updateEmployeePasswordSaga({
  payload,
}: IUpdateEmployeePassword) {
  const { id, password } = payload;

  try {
    yield put(setLoading());

    yield call(changeEmployeePassword, id, password);

    yield put(setEditPasswordSuccess());
  } catch (error) {
    yield put(setError());

    errorToast(undefined, undefined, error);
  }
}

export default function* employeesSagas() {
  yield all([
    takeEvery(
      EMPLOYEES_ACTION_TYPES.GET_INITIAL_EMPLOYEES_REQUEST,
      getInitialEmployeesSaga,
    ),
    takeEvery(EMPLOYEES_ACTION_TYPES.GET_EMPLOYEES_REQUEST, getEmployeesSaga),
    takeEvery(
      EMPLOYEES_ACTION_TYPES.CREATE_EMPLOYEE_REQUEST,
      createEmployeeSaga,
    ),
    takeEvery(
      EMPLOYEES_ACTION_TYPES.UPDATE_EMPLOYEE_REQUEST,
      updateEmployeeSaga,
    ),
    takeEvery(
      EMPLOYEES_ACTION_TYPES.DELETE_EMPLOYEE_REQUEST,
      deleteEmployeeSaga,
    ),
    takeEvery(
      EMPLOYEES_ACTION_TYPES.UPDATE_PASSWORD,
      updateEmployeePasswordSaga,
    ),
  ]);
}
