import { useJsApiLoader } from "@react-google-maps/api";

type JsLoaderOptions = Parameters<typeof useJsApiLoader>[0];

export const apiOptions: JsLoaderOptions = {
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_KEY ?? "",
  language: "de",
  region: "de",
  libraries: ["geometry"],
};

const mapStyles = [
  { featureType: "poi", stylers: [{ visibility: "off" }] },
  { featureType: "transit", stylers: [{ visibility: "off" }] },
];

export const options = {
  fullscreenControl: false,
  mapTypeControl: false,
  mapTypeId: "hybrid",
  rotateControl: false,
  scaleControl: false,
  streetViewControl: false,
  zoomControl: false,
  styles: mapStyles,
};

export const mapContainerStyle: React.CSSProperties = {
  width: "100%",
  height: "100%",
  position: "relative",
};
