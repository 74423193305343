import { MAX_LENGTH, VALIDATION_ERRORS, VALIDATION_REGEX } from "Constants";
import { useMemo } from "react";
import * as Yup from "yup";

export const useValidationSchema = () =>
  useMemo(
    () =>
      Yup.object().shape({
        label: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          VALIDATION_ERRORS.MAX_LENGTH,
        ),
        companyName: Yup.string()
          .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH)
          .required(VALIDATION_ERRORS.REQUIRED),
        street: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          VALIDATION_ERRORS.MAX_LENGTH,
        ),
        zipCode: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          VALIDATION_ERRORS.MAX_LENGTH,
        ),
        city: Yup.string().max(
          MAX_LENGTH.DEFAULT,
          VALIDATION_ERRORS.MAX_LENGTH,
        ),
        phone: Yup.string().matches(
          VALIDATION_REGEX.phone,
          VALIDATION_ERRORS.PHONE,
        ),
        mobile: Yup.string().matches(
          VALIDATION_REGEX.phone,
          VALIDATION_ERRORS.PHONE,
        ),
        email: Yup.string()
          .matches(VALIDATION_REGEX.email, VALIDATION_ERRORS.VALID_EMAIL)
          .max(MAX_LENGTH.DEFAULT, VALIDATION_ERRORS.MAX_LENGTH),
        notes: Yup.string().max(
          MAX_LENGTH.LONG_STRING,
          VALIDATION_ERRORS.MAX_LENGTH,
        ),
      }),
    [],
  );
