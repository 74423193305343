import {
  convertAreaToCustom,
  convertAreaToDefault,
} from "@ero/app-common/util/convertArea";
import { DeleteForever, Save } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tabs as MuiTabs,
  Tab,
} from "@mui/material";
import { CloseButton } from "Components/closeButton/closeButton";
import { type AppState } from "Store";

import { HTTP_METHOD } from "@ero/app-common/enums/HTTP_METHOD";
import { SIZE_FIELD } from "@ero/app-common/enums/sizeField";
import { SqMeter } from "@ero/app-common/util/Units";
import { ParcelResponseBody } from "@ero/app-common/v2/routes/models/parcel";
import { baseUrl, SubUrls } from "@ero/app-common/v2/routes/parcel";
import { schema } from "@ero/app-common/v2/routes/schema/parcel";
import { ConfirmDialog } from "Components/confirmDialog";
import { Loader } from "Components/loader";
import { useFullscreenContext } from "Contexts/fullScreenContext";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sagaActions } from "Store/parcels/parcelsSagaActions";
import { parcelsSlice } from "Store/parcels/parcelsSlice";
import { ParcelHistory } from "./screens/history/parcelHistory";
import { ParcelData } from "./screens/parcelData";
import { TabPanel } from "./screens/parcelData/components/tabPanel";
import { FormikValues, useValidationSchema } from "./validationConfig";

type ParcelModalProps = {
  parcel?: ParcelResponseBody | null;
  isOpen: boolean;
  close: () => void;
};

enum TABS {
  OVERVIEW,
  HISTORY,
}

const updateSchema = schema[baseUrl][SubUrls.BY_ID][HTTP_METHOD.PATCH]["body"];
const createSchema = schema[baseUrl][SubUrls.ROOT][HTTP_METHOD.POST]["body"];

export const ParcelModal: React.FC<ParcelModalProps> = ({
  parcel,
  isOpen,
  close,
}) => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const { fullscreenContainer } = useFullscreenContext();

  const { unitOfMeasurement } = useSelector(
    (store: AppState) => store.auth.companyData,
  );

  const { updateLoading, updateDone } = useSelector(
    (store: AppState) => store.parcels,
  );

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const [currentTab, setCurrentTab] = useState(TABS.OVERVIEW);

  const editMode = useMemo(() => parcel?._id !== undefined, [parcel?._id]);

  const validationSchema = useValidationSchema();

  const initialValues: FormikValues = useMemo(
    () => ({
      parcelId: parcel?._id ?? undefined,
      name: parcel?.name ?? "",
      customer: parcel?.customer._id ?? "",
      grapeVariety: parcel?.grapeVariety?._id ?? "",
      rowAmount: parcel?.rowAmount ?? "",
      size: parcel?.size
        ? convertAreaToCustom(parcel?.size, unitOfMeasurement)
        : "",
      mark: parcel?.mark ?? "",
      regionette: parcel?.regionette ?? "",
      position: parcel?.position ?? undefined,
      shape: parcel?.shape ?? undefined,
      notes: parcel?.notes ?? "",
      isClosedPolygon: (parcel?.shape?.length ?? 0) > 2,
      splittedParcels: [],
      mergedParcelIds: [],
    }),
    [parcel, unitOfMeasurement],
  );

  const createParcel = useCallback((values: FormikValues) => {
    const data = createSchema.cast(values, {
      stripUnknown: true,
      // ignore invalid data
      assert: false,
    });
    dispatch(
      sagaActions.createParcel({
        parcel: data,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateParcel = useCallback((id: number, values: FormikValues) => {
    const data = updateSchema.cast(values, {
      stripUnknown: true,
      // ignore invalid data
      assert: false,
    });

    // send empty grapeVariety as null in order to clear field
    data.grapeVariety = data.grapeVariety ?? null;

    dispatch(
      sagaActions.updateParcel({
        id,
        update: data,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createSplittedParcels = useCallback((values: FormikValues) => {
    values.splittedParcels.forEach((splittedParcel, index) => {
      const data = createSchema.cast(
        {
          ...values,
          shape: splittedParcel.shape,
          size: convertAreaToDefault(
            splittedParcel.size as SqMeter,
            unitOfMeasurement,
            i18n.language === "us" ? "en" : "de",
          ),
          sizeField: SIZE_FIELD.SIZE_AUTOMATIC,
          name: values.name + "_" + (index + 2).toString(),
        },
        {
          stripUnknown: true,
          // ignore invalid data
          assert: false,
        },
      );

      dispatch(
        sagaActions.createParcel({
          parcel: data,
        }),
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteMergedParcels = useCallback(
    (mergedParcelIds: FormikValues["mergedParcelIds"]) => {
      dispatch(sagaActions.deleteParcels(mergedParcelIds));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleFormSubmit = useCallback(
    (values: FormikValues): void => {
      values.size = values?.size
        ? convertAreaToDefault(
            values.size as SqMeter,
            unitOfMeasurement,
            i18n.language === "us" ? "en" : "de",
          )
        : 0;

      if (parcel?._id) {
        updateParcel(parcel._id, values);
      } else {
        createParcel(values);
      }

      if (values.splittedParcels) {
        createSplittedParcels(values);
      }

      if (values.mergedParcelIds) {
        deleteMergedParcels(values.mergedParcelIds);
      }
    },
    [
      createParcel,
      createSplittedParcels,
      deleteMergedParcels,
      i18n.language,
      parcel?._id,
      unitOfMeasurement,
      updateParcel,
    ],
  );

  const deleteParcel = useCallback(() => {
    if (parcel?._id) {
      dispatch(sagaActions.deleteParcels([parcel?._id]));
    }
    setShowConfirmDeleteModal(false);
    close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, parcel?._id]);

  useEffect(() => {
    if (updateDone) {
      dispatch(parcelsSlice.actions.resetUpdateDone());
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateDone]);

  return (
    <Dialog
      open={isOpen}
      onClose={close}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      container={fullscreenContainer}
    >
      <DialogTitle>
        {parcel?._id ? parcel.name : t("parcels.create")}
      </DialogTitle>
      <CloseButton onClose={close} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <MuiTabs
          value={currentTab}
          onChange={(_, newTab) => setCurrentTab(newTab)}
          variant="fullWidth"
        >
          <Tab
            label={t("general.labels.parcelData")}
            id={`parcel-model-tabs-${TABS.OVERVIEW}`}
          />
          {editMode && (
            <Tab
              label={t("general.labels.history")}
              id={`parcel-model-tabs-${TABS.HISTORY}`}
            />
          )}
        </MuiTabs>
      </Box>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validateOnMount={editMode}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({ dirty, isValid }) => (
          <Form>
            <DialogContent dividers>
              <TabPanel index={currentTab} value={TABS.OVERVIEW}>
                <ParcelData />
              </TabPanel>
              {editMode && (
                <TabPanel index={currentTab} value={TABS.HISTORY}>
                  <ParcelHistory parcel={parcel} />
                </TabPanel>
              )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button
                onClick={() => {
                  setShowConfirmDeleteModal(true);
                }}
                startIcon={<DeleteForever />}
                disabled={updateLoading || !editMode}
              >
                {t("general.buttons.delete")}
              </Button>
              <ConfirmDialog
                isOpen={showConfirmDeleteModal}
                onClose={() => setShowConfirmDeleteModal(false)}
                onApprove={deleteParcel}
              />
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={
                  (!dirty && !!parcel?._id) || !isValid || updateLoading
                }
                startIcon={!updateLoading && <Save />}
              >
                {updateLoading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
