import { Save } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Loader } from "Components";
import { CloseButton } from "Components/closeButton/closeButton";
import { getCustomerPersonsInitial, resetState } from "Store/customerPersons";
import {
  createCustomer as defaultCreateCustomer,
  resetMeta as defaultResetMeta,
  updateCustomer as defaultUpdateCustomer,
} from "Store/customers";
import { LocalCustomerType } from "Store/customers/specs";
import { RequestBody } from "Types";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { type AppState } from "../../Store";
import { GeneralInformation } from "./screens";
import { useValidationSchema } from "./validationConfig";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  customer?: Partial<LocalCustomerType>;
  getStoreState?: (store: AppState) => any;
  onSuccess?: () => void;
  resetMeta?: () => AnyAction;
  createCustomer?: (data: RequestBody<"/customers/create">) => AnyAction;
  updateCustomer?: (
    data: RequestBody<"/customers/update">,
    id: number,
  ) => AnyAction;
}

export const CustomerInformationModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  customer,
  getStoreState = (state: AppState) => state.customers,
  onSuccess,
  resetMeta = defaultResetMeta,
  createCustomer = defaultCreateCustomer,
  updateCustomer = defaultUpdateCustomer,
}: IProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const validationSchema = useValidationSchema();

  const { success, loading } = useSelector(getStoreState);

  const { list: employees } = useSelector(
    (state: AppState) => state.customerEmployees,
  );

  useEffect(() => {
    dispatch(resetMeta());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetMeta]);

  useEffect(() => {
    if (customer?._id) {
      dispatch(
        getCustomerPersonsInitial(customer?._id, { page: -1, pageLength: -1 }),
      );
    } else {
      dispatch(resetState());
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const editMode = useMemo(() => !!customer?._id, [customer?._id]);

  const initialValues = useMemo(
    () => ({
      ...customer,
      label: customer?.label || "",
      companyName: customer?.companyName || "",
      street: customer?.street || "",
      zipCode: customer?.zipCode || "",
      city: customer?.city || "",
      phone: customer?.phone || "",
      mobile: customer?.mobile || "",
      email: customer?.email || "",
      notes: customer?.notes || "",
      mainContact: customer?.mainContact?._id || "",
    }),
    [customer],
  );

  useEffect((): void => {
    if (success) {
      if (onSuccess) onSuccess();
      dispatch(resetMeta());
      onClose();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success, onSuccess, onClose, resetMeta]);

  const handleFormSubmit = useCallback(
    (values): void => {
      const { mainContact: _, ...rest } = values;
      const update = rest;

      if (customer?._id) {
        dispatch(updateCustomer(update, customer?._id));
      } else {
        dispatch(createCustomer(update));
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [customer?._id, updateCustomer, createCustomer],
  );

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="xl"
      scroll="body"
    >
      <DialogTitle>
        {editMode ? customer?.companyName : t("customers.create")}
      </DialogTitle>
      <CloseButton onClose={onClose} />
      <Formik
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
        validationSchema={validationSchema}
      >
        {({ isValid, dirty }) => (
          <Form name="GeneralInformation" autoComplete="off">
            <DialogContent dividers>
              <GeneralInformation
                persons={employees}
                mainContactId={customer?.mainContact?._id}
                editMode={editMode}
              />
            </DialogContent>
            <DialogActions>
              <Button
                type="submit"
                disabled={!isValid || !dirty || loading}
                variant="contained"
                startIcon={!loading && <Save />}
              >
                {loading && <Loader size={30} />}
                {t("general.buttons.save")}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
