import { Directions, DirectionsOff } from "@mui/icons-material";
import { Fab, Tooltip } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface ITrackControlV2 {
  showTrack: boolean;
  onClick?: (show: boolean) => void;
}

export const TrackControlV2: React.FC<ITrackControlV2> = ({
  showTrack: showTrackInitial,
  onClick,
}) => {
  const [t] = useTranslation();

  const [showTrack, setShowTrack] = useState<boolean>(showTrackInitial);

  const onClickHandler = useCallback(() => {
    if (onClick) onClick(!showTrack);
    setShowTrack(!showTrack);
  }, [onClick, showTrack]);

  const tooltipText = useMemo(() => {
    if (showTrack) {
      return t("components.map.controlButtons.hideDriverTrack");
    } else {
      return t("components.map.controlButtons.showDriverTrack");
    }
  }, [showTrack, t]);

  return (
    <Tooltip title={tooltipText}>
      <Fab onClick={onClickHandler}>
        {showTrack ? <DirectionsOff /> : <Directions />}
      </Fab>
    </Tooltip>
  );
};
